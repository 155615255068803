<template>
  <div>
    <div class="row">
      <div
        class="col-12 col-lg-3 text-center"
        style="cursor: pointer"
        @click="$router.push('/add-import')"
      >
        <div class="card card-body">
          <h2><i class="fa fa-upload"></i> <br />استيراد الطلاب</h2>
          <br />(من ملف الارشاد الطلابي من نور)
        </div>
      </div>
      <div
        class="col-12 col-lg-3 text-center"
        style="cursor: pointer"
        @click="$router.push('/_committees/create')"
      >
        <div class="card card-body">
          <h2><i class="fa fa-star"></i> <br />توزيع اللجان</h2>
          <br />(توزيع اللجان بعد استيراد الطلاب)
        </div>
      </div>
      <div
        class="col-12 col-lg-3 text-center"
        style="cursor: pointer"
        @click="$router.push('/_committees/kushof')"
      >
        <div class="card card-body">
          <h2><i class="fa fa-print"></i> <br />النماذج والكشوف</h2>
          <br />(مثل حضر غياب والخ...)
        </div>
      </div>
      <a
        class="col-12 col-lg-3 text-center"
        style="cursor: pointer"
        href="https://www.youtube.com/watch?v=NdqZtSJDubo"
        target="_blank"
      >
        <div class="card card-body">
          <h2><i class="fa fa-youtube"></i> <br />شرح الاستخدام</h2>
          <br />(مشاهدة شرح مبسط)
        </div>
      </a>
      <div class="col-12 col-lg-6 c g">
        <br><br>
        <div class="card card-body text-center">
          <a href="https://www.youtube.com/playlist?list=PLEurVG9qb-Cr30Ib4WfwVdKJw3NXB4RTA" target="_blank" class="btn btn-lg btn-danger">
          <i class="fa fa-video"></i>
          للشروحات اضغط هنا
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    if(!checkPer("committees")){
        this.$router.push('/NotPermitted')
        return false;
    }
    },
};
</script>

<style>
</style>